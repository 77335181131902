import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer">
            <div class="card clearfix">
              <span>
                  &copy; {{currentYear}} Labwarehouse Inc., All Rights Reserved
              </span>
              <span class="p-ml-2">v{{AppVersion}}</span>                
            </div>
        </div>
    `
})
export class AppFooterComponent {

  public currentYear: number = new Date().getFullYear();
  public AppVersion = '3.2.0';

}
