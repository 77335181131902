//Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// https://www.npmjs.com/package/@aspnet/signalr   *** npm i @aspnet/signalr ****
import { HubConnectionBuilder, HubConnection, HttpTransportType } from '@aspnet/signalr';

//Services
import { CsrService } from '../csr.service';
import { DepartmentService } from '../department/department.service';
import { AccountService } from '../../account/account.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { AssignedToList } from './assigned-to-list';
import { CsrQuickSearch } from './csr-quick-search';
import { Csr } from '../csr';
import { Department } from '../department/department';
import { User } from '../../account/model/user';
import { Root } from '../chart-data-dto';

//Primeng
import { MessageService, SelectItem } from 'primeng/api';
import { state } from '@angular/animations';

// Moment
import * as moment from 'moment';

interface SelectItemModel {
  name: string,
  code: string
}

@Component({
  selector: 'app-quicksearch',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
  styles: []
})
export class QuickSearchComponent implements OnInit {

  public title = 'Quick Search';
  public Csrs: CsrQuickSearch[] = [];
  public pagingLst: SelectItemModel[] = [];
  public eventsScheduleOptions: SelectItem[] = [];
  public departmentId = 0;
  public departments: Department[] = [];
  public department: Department;
  public strDepartment = '';
  public selectedDepartment: Department;
  public userLogged: User;
  public status = 0;
  public filterComments = 0;
  public numberOfRecords: SelectItemModel = {} as SelectItemModel;
  public loading: boolean;
  public cols: any[];
  public deleteModal = false;
  public issueid = 0;
  public assignedToList: AssignedToList[] = [];
  public issue: Csr;
  public hubConnection!: HubConnection;
  public fromDate: Date;
  public toDate: Date;
  public displaySetFilterParams = false;
  public waitingScreen = false;
  public displayChart = false;
  public root: Root = {} as Root;
  public titleChart: String = 'Issue Types';

  public selectNumber = {
    cero: 0,
    one: 1,
    two: 2,
    three: 3,
    threePointFive: 3.5,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
    twentyone: 21,
    twentytwo: 22,
    twentythree: 23,
    twentyfour: 24
  };

  constructor(private readonly csrService: CsrService,
    private readonly accountService: AccountService,
    private readonly departmentService: DepartmentService,
    private readonly messageService: MessageService,
    private readonly breadcrumbService: AppBreadcrumbService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {

    this.pagingLst = [
      { name: '100', code: '100' },
      { name: '500', code: '500' },
      { name: '1000', code: '1000' },
      { name: '2000', code: '2000' },
      { name: '3000', code: '3000' },
      { name: 'All', code: 'All' }
    ];

    this.eventsScheduleOptions = [
      { label: '', value: undefined },
      { label: 'Yes', value: 'True' },
      { label: 'No', value: 'False' }
    ];

    this.fromDate = new Date(moment().subtract(10, 'day').format('l'));
    this.toDate = new Date(moment().endOf('day').format('l'));
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.department = { id: 0, name: '', description: '', inactive: false } as Department;
      this.departmentId = this.departmentService.getDepartmentID();

      //id:0 = MyCMR | id:1 = Admin
      //status:0 = N/A
      //status:1 = New Request
      //status:2 = Special projects
      //status:3 = In Progress
      //status:4 = Completed
      //status:5 = Not Completed
      //status:6 = Rejected
      //status:7 = Holding Administrative Clearance

      this.route.params.subscribe(
        (params: any) => {

          this.status = +params['status'];
          this.filterComments = +params['filterComments'];
          this.userLogged = this.getlocalUser();
          this.listenerNewCsr();

          if (this.status === this.selectNumber.three) {
            this.numberOfRecords = this.pagingLst[0];
          } else {
            this.numberOfRecords = this.pagingLst[this.selectNumber.three];
          }

          // Admin mode ********
          switch (this.status) {
            case this.selectNumber.one:
              this.getCMRbyStatus('New Request');
              break;
            case this.selectNumber.two:
              this.getCMRbyStatus('In Progress');
              break;
            case this.selectNumber.three:
              this.getCsrByStatusNumberOfRecords('Completed');
              break;
            case this.selectNumber.four:
              this.getCMRbyStatus('Waiting for additional information');
              break;
            case this.selectNumber.five:
              this.getCMRbyStatus('Special projects');
              break;
            case this.selectNumber.six:
              this.getCMRbyStatus('Not Completed');
              break;
            case this.selectNumber.seven:
              this.getCMRbyStatus('Rejected');
              break;
            case this.selectNumber.eight:
              this.getCMRbyStatus('Holding Administrative Clearance');
              break;
            case this.selectNumber.nine:
              this.getCMRbyStatus('Security events log');
              break;
            default:
              break;
          }

        });

      this.breadcrumbService.setItems([
        { label: 'csr' },
        { label: 'deparment', routerLink: [`/csr/department/${this.departmentId}`] },
        { label: 'dashboard', routerLink: ['/csr/dashboard'] },
        { label: 'quick-search', routerLink: [`/csr/quick-search/${this.status}/${this.filterComments}`] }
      ]);

      this.getDepartments();
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public listenerNewCsr() {

    let builder = new HubConnectionBuilder();
    //this.hubConnection = builder.withUrl("http://localhost:57232/newcsr",
    this.hubConnection = builder.withUrl("https://cmr.labwarehousepr.com/newcsr",
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      }).build();

    this.hubConnection.start()
      .then(() => console.log("Connection started"))
      .catch(error => console.log(error));

    this.hubConnection.on("BroadcastMessage", (message) => {

      this.refreshIssueList();
    });

  }

  public getCMRbyStatus(status: string): void {

    if (this.departmentId === 0) {
      this.router.navigate(['/cmr/department', 1]);
    }

    this.loading = true;

    this.csrService.getCsrByStatus(status, this.departmentId)
      .subscribe((res: any) => {
        this.Csrs = res as CsrQuickSearch[];
        this.loadColumns();
        this.loading = false;
        this.getDepartment();

        if (this.filterComments === 1) {
          this.filterComments = 0;
          this.getFilterComments();
        }

        this.title = `Quick Search by: [${status}] - ${this.Csrs.length.toString()}`;
        this.getAssignedToList(status);
      },
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });
          this.loading = false;                   
                    
        });
  }

  public getCsrByStatusNumberOfRecords(status: string): void {

    if (this.departmentId === 0) {
      this.router.navigate(['/cmr/department', 1]);
    }

    this.loading = true;

    this.csrService.getCsrByStatusNumberOfRecords(status, this.departmentId, this.numberOfRecords.code)
      .subscribe((res: any) => {
        this.Csrs = res as CsrQuickSearch[];
        this.loadColumns();
        this.loading = false;
        this.getDepartment();

        if (this.filterComments === 1) {
          this.filterComments = 0;
          this.getFilterComments();
        }

        this.title = `Quick Search by: [${status}] - ${this.Csrs.length.toString()}`;
        this.getAssignedToList(status);
      },
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });
          this.loading = false;          

        });
  }

  public getAssignedToList(status: string): void {

    if (this.departmentId === 0) {
      this.router.navigate(['/cmr/department', 1]);
    }

    this.csrService.getAssignedToList(status, this.departmentId)
      .subscribe((res: any) => this.assignedToList = res as AssignedToList[],
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });

        });
  }  

  public getDepartment(): void {

    this.departmentService.getDepartment(this.departmentId)
      .subscribe((res: any) => {
        this.department = res as Department;
        this.strDepartment = this.department.name;
        //console.log(this.department.name);
      },
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe((res: any) => this.departments = res as Department[],
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getFilterComments(): void {

    const filterCsrs = this.Csrs.filter(a => a.comments.length > 0);

    if (filterCsrs !== undefined) {
      this.Csrs = filterCsrs;
    }
  }

  public selectCsr(issueid: number): void {

    const url = `/csr/csr-details/${issueid}`;

    this.router.navigate([]).then((result) => {
      window.open(url, '_blank');
    });
   
    //this.router.navigate([url]);
  }

  public loadColumns(): void {

    if (!this.isMobile()) {
      this.cols = [
        { field: 'id', header: 'CSR #' },
        { field: 'projectName', header: 'Project' },
        { field: 'subject', header: 'Subject' },
        { field: 'clientIssueBy', header: 'Client' },
        { field: 'assignedTo', header: 'Assigned To' },
        { field: 'scheduleDate', header: 'ES' },
        { field: 'dateSubmitted', header: 'Submitted' }
      ];
    } else {
      this.cols = [
        { field: 'id', header: 'CSR #' },
        { field: 'projectName', header: 'Project' },
        { field: 'subject', header: 'Subject' },
        { field: 'clientIssueBy', header: 'Client' },
        { field: 'assignedTo', header: 'Assigned To' }
      ];
    }
  }

  public setPin(csr: CsrQuickSearch, pin: boolean): void {

    this.getIssue(csr, pin);
  }

  public getIssue(csr: CsrQuickSearch, pin: boolean): void {

    this.csrService.getCsr(csr.id)
      .subscribe((res: any) => {

        this.issue = res as Csr;

        // Para evitar error en primeng p-calendar.
        this.issue.dateSubmitted = this.handleDate(this.issue.dateSubmitted!)!;
        this.issue.inProgressDate = this.handleDate(this.issue.inProgressDate!)!;
        this.issue.completedDate = this.handleDate(this.issue.completedDate!)!;
        this.issue.scheduleDate = this.handleDate(this.issue.scheduleDate!)!;                
      },
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public handleDate(dateSource: Date | null): Date | null {

    if (dateSource !== null) {

      return new Date(dateSource.toString());
    }

    return dateSource;
  } 

  public showDeleteConf(id: number): void {
    this.issueid = id;
    this.deleteModal = true;
  }

  public deleteCsr(): void {

    this.deleteModal = false;

    this.csrService.deleteCsr(this.issueid.toString())
      .subscribe((res: any) => {

        this.messageService.add({ key: 'quicksearchkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
      },
        (error: any) => {

          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          this.refreshIssueList();
        });

  }

  public manualRefresh(): void {    
    this.refreshIssueList();
    this.messageService.add({ key: 'quicksearchkey', severity: 'info', summary: 'Manual reload!', detail: `Get ${this.Csrs.length} records` });
  }

  public refreshIssueList(): void {
    if (this.isUserLoggedIn()) {

      // Admin mode ********
      switch (this.status) {
        case this.selectNumber.one:
          this.getCMRbyStatus('New Request');
          break;
        case this.selectNumber.two:
          this.getCMRbyStatus('In Progress');
          break;
        case this.selectNumber.three:
          this.getCsrByStatusNumberOfRecords('Completed');
          break;
        case this.selectNumber.four:
          this.getCMRbyStatus('Waiting for additional information');
          break;
        case this.selectNumber.five:
          this.getCMRbyStatus('Special projects');
          break;
        case this.selectNumber.six:
          this.getCMRbyStatus('Not Completed');
          break;
        case this.selectNumber.seven:
          this.getCMRbyStatus('Rejected');
          break;
        case this.selectNumber.eight:
          this.getCMRbyStatus('Holding Administrative Clearance');
          break;
        case this.selectNumber.nine:
          this.getCMRbyStatus('Security events log');
          break;
        default:
          break;
      }
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public getChartDataIssuType(): void {

    if (!moment(this.fromDate).isSameOrBefore(this.toDate)) {

      this.messageService.add({ key: 'quicksearchkey', severity: 'warn', summary: 'Warnning', detail: 'From Date must be greater than To Date.' });

      return;
    }

    this.waitingScreen = true;

    this.csrService.getChartDataIssueType(this.fromDate, this.toDate, this.selectedDepartment.id)
      .subscribe((data: any) => this.root = data as Root,
        (error: any) => {
          this.displaySetFilterParams = false;
          this.waitingScreen = false;
          this.messageService.add({ key: 'quicksearchkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          console.log(this.root);
          this.displaySetFilterParams = false;
          this.waitingScreen = false;
          this.displayChart = true;
          this.titleChart = `${this.selectedDepartment.name} - Issue Type`;
        });
  }

  public isMobile() {
    return window.innerWidth <= 640;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

}
