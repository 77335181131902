//Angular
import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { GuideService } from '../guide.service';
import { AccountService } from '../../account/account.service';
import { ProjectService } from '../../project/project.service';

//Core
import { Guide } from '../guide';
import { Project } from '../../project/project';
import { User } from '../../account/model/user';

//Primeng
import { MessageService, Message } from 'primeng/api';

@Component({
  selector: 'app-guide-add',
  templateUrl: './guide-add.component.html'
})
export class GuideAddComponent implements OnInit {

  public title = 'Add New - Implementation Guide';
  public projects: Project[] = [];
  public project: Project;
/*  public selectedProject: Project = {} as Project;*/
;
  public userLogged: User;
  public guide: Guide;
  public filesToUpload: File[];
  public loading = false;
  public msgs: Message[];

  @ViewChild('guidetitle', { static: false })
  public guidetitleVariable: any;
  @ViewChild('myInput', { static: false })
  public myInputVariable: any;
  @ViewChild('currentversion', { static: false })
  public currentVersionVariable: any;

  constructor(private readonly guideService: GuideService,
    private readonly projectService: ProjectService,
    private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) { }

  public ngOnInit(): void {

    this.guide = {} as Guide;
    this.userLogged = {} as User;

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();
    }

    this.getProjects();
  }

  public getProjects(): void {

    this.projectService.getProjects()
      .subscribe(res => {
        this.projects = res as Project[];
      },
        (error: any) => {

          this.messageService.add({ key: 'guideaddkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public fileChangeEvent(fileInput: any): void {

    this.filesToUpload = fileInput.target.files as File[];
  }

  public onSubmit(): void {

    if (this.filesToUpload !== undefined) {

      if (this.filesToUpload.length === 0) {

        this.messageService.add({ key: 'guideaddkey', severity: 'warn', summary: 'File input', detail: 'Please select file.' });
        return;
      }

      this.loadingSubmit(true);

      this.guide.project = this.project;
      this.guide.projectid = this.project.id;

      const formData = new FormData();
      const GuideDate = new Date();

      let file = this.filesToUpload[0];
      formData.append(file.name, file);
      formData.append('GuideTitle', this.guide.guidetitle);
      formData.append('GuideDate', GuideDate.toString());
      formData.append('ImagefileName', 'pdf.jpg');
      formData.append('fileName', file.name);
      formData.append('Inactive', 'false');
      formData.append('ProjectId', this.project.id.toString());
      formData.append('UserId', this.userLogged.id);
      formData.append('CurrentVersion', this.guide.currentVersion);

      this.guideService.addGuide(formData)
        .subscribe((res: any) => {
        
          this.loadingSubmit(false);
          this.messageService.add({ key: 'guideaddkey', severity: 'success', summary: 'Upload File', detail: 'file upload succeeded.' });

          this.filesToUpload = [];
          this.guidetitleVariable.nativeElement.value = '';
          this.project = {} as Project;
          this.myInputVariable.nativeElement.value = '';
          this.currentVersionVariable.nativeElement.value = '';
        },
          (error: any) => {

            this.messageService.add({ key: 'guideaddkey', severity: 'error', summary: 'Error', detail: error });

          });
    }
    else {
      this.messageService.add({ key: 'guideaddkey', severity: 'warn', summary: 'File input', detail: 'Please select file.' });
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    return this.accountService.getLoggedInUser();
  }

  public loadingSubmit(value: boolean): void {

    if (value === true) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
  }

}
